* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  overflow-x: hidden;
}

body {
  width: 100vw;
  height: 100svh;
  overflow-y: auto; 
  overflow-x: hidden;
  position: relative;
  -webkit-overflow-scrolling: touch; 
  font-family: "Poppins", serif;
  background-color: #E6E7EB;
  margin: 0;
  padding: 0;
  
  /* Hide the default scrollbar */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-border_radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }

  /* Hide scrollbar when not in use */
  ::-webkit-scrollbar-thumb:vertical {
    transition: background 0.2s;
  }

  /* Hide scrollbar when not in use (horizontal) */
  ::-webkit-scrollbar-thumb:horizontal {
    transition: background 0.2s;
  }

  /* Custom scrollbar on hover (vertical) */
  ::-webkit-scrollbar-thumb:vertical:hover {
    background: rgba(0, 0, 0, 0.6);
  }

  /* Custom scrollbar on hover (horizontal) */
  ::-webkit-scrollbar-thumb:horizontal:hover {
    background: rgba(0, 0, 0, 0.6);
  }
}